@use "../config/" as *;

.homepage {
    .main-wrap {
        padding-top: calc(var(--header-height) + var(--eventstrip));
    }

    .header-wrap {
        top: var(--eventstrip);
    }
}
.no-live-event{
    .main-wrap{
        padding-top: var(--header-height);
    }
    .header-wrap{
        top: 0;
    }
}

@each $event,$event-key in $events{
    @include event-strip($event,$event-key);
}

.waf-eventstrip.event-strip {
    padding-block: 0;
    height: var(--eventstrip);
    z-index: var(--zindex-eventstrip);
    @include position(0, null, null, 0, fixed);
    @extend %w-100;
    @extend %c-white-10;
    @extend %m-zero;
    
    .layout-wrapper {
        max-width: 100%;
        @extend %m-zero;
    }

    .waf-head {
        @extend %d-none;
    }

    .card-list {
        animation: marquee 40s 1s infinite linear;
        gap: 4.2rem;
        transform: translateX(100vw);
        @extend %flex-n-n;

        &:hover {
            animation-play-state: paused;
        }
    }

    .event {

        &-name {
            order: -1;
            width: max-content;
            @extend %m-r-half;
            @extend %c-white-10;
            @extend %font-12-pr;
        }
        &-venue {
            @extend %m-r-half;
            @extend %c-white-10;
            @extend %font-12-pr;
        }
        &-item {
            width: max-content;
            position: relative;
            height: var(--eventstrip);
            @extend %flex-n-c;

            &.live {
                .event-status {
                    &::before {
                        content: '';
                        width: 2rem;
                        height: 2rem;
                        aspect-ratio: 1/1;
                        flex-shrink: 0;
                        background-image: url('/static-assets/images/svg/live-white.svg?v=#{$image-version}');

                    }
                }
            }
        }

        &-status {
            order: 1;
            @extend %font-12-pb;
            @extend %gap-quater;
            @extend %uppercase;
            @extend %flex;

        }

        &-content {
            @extend %flex-n-c;
        }

        &-action {
            @include position(0, 0);
            @extend %w-100;
            @extend %h-100;

            .text {

                @extend %font-zero;
            }
        }
    }
    &.olympic-qualifying-tournament, &.olympic-games {
        .event {
            &-status, &-venue, &-name {
                @extend %c-blue-10;
            }
            &-status::before {
                filter: brightness(0%) invert(8%) sepia(34%) saturate(6714%) hue-rotate(243deg) brightness(81%) contrast(120%);
            }
        }
    }
}