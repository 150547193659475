@use "../config/" as *;
.waf-standings {
    padding: 0.1rem;
    margin: var(--three-space) var(--half-space);
    @include linear-gradient(140deg, hsl(var(--hsl-c-white)/0.7) 0%, hsl(var(--hsl-c-white)/0) 51.04%, hsl(var(--hsl-c-white)/0.7) 100%);
    @extend %p-zero;
    @extend %half-radius;
    @extend %head-position;
    .layout-wrapper {
        overflow: hidden;
        @extend %half-radius;
    }
    .head-wrap {
        flex-wrap: wrap;
    }
    .head-tab {
        min-height: 5.6rem;
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        border-bottom: 0.1rem solid hsl(var(--hsl-c-white)/0.7);
        z-index: var(--zindex-tab);
        @extend %w-100;
        @extend %c-blue-bg-10;
        @extend %flex-c-c;
        a {
            @extend %c-white-10;
        }
    }
    .tab-container {
        @extend %c-white-bg-10;
    }
    .waf-head {
        @extend %c-white-10;
        @extend %c-blue-bg-10;
        @extend %p-full;
        .title {
            @extend %c-white-10;
        }
    }
    // tabs sections
    .tabs {
        @include flex-config(null, null, space-between);
        @extend %m-t-zero;
        @extend %m-b-full;
        @extend %gap-full;
    }
    .tab {
        &-section {
            @extend %c-blue-bg-10;
            @extend %w-100;
            @extend %p-x-full;
        }
        &-text {
            @include font(16);
            @extend %c-white-10;
        }
        &-name {
            width: max-content;
            @extend %c-white-10;
            @extend %p-zero;
            &.active {
                border-color: var(--c-white);
            }
        }
    }
    .tabs.secondary {
        overflow: auto;
        @extend %gap-half;
        @include flex-config(null, null, unset);
        .tab-name {
            padding-inline: 1rem;
            padding-block: .5rem;
            @include border(1, c-white);
            @extend %text-center;
            &.active {
                @extend %c-white-bg-10;
                @extend %c-blue-10;
            }
        }
    }
    .table {
        @extend %p-b-full;
        @extend %p-x-full;
        @extend %flex;
        &-responsive {
            height: 39rem;
            overflow-y: auto;
            overflow-x: hidden;
            @include custom-scroll();
        }
        &-left {
            width: calc(100% - 10rem);
        }
        &-right {
            width: 10rem;
            flex-shrink: 0;
            @extend %text-right;
            .text {
                // @extend %c-orange-10;
                @extend %font-12-pr;
                span {
                    @extend %p-l-quarter;
                }
            }
            .table {
                &-row {
                    @include flex-config(null, null, flex-end);
                }
                &-data {
                    justify-content: flex-end;
                }
            }
        }
        &-row {
            padding-block: 1.2rem;
            @extend %flex;
            &:not(:last-child) {
                @include border(1, c-blue-light, 5, bottom);
            }
            &.up {
                .table-data {
                    .text {
                        @extend %flex-c-c;
                        &::before {
                            @include icon("\e802", 30);
                            @extend %c-success-10;
                        }
                    }
                }
            }
            &.down {
                .table-data {
                    .text {
                        @extend %flex-c-c;
                        &::before {
                            @include icon("\e802", 30);
                            @include rotate(180);
                            @extend %c-error-10;
                        }
                    }
                }
            }
        }
        &-data {
            height: 4rem;
            text-align: inherit;
            @extend %flex-n-c;
        }
        &-info {
            @extend %flex-n-c;
        }
        &-image {
            width: 4rem;
            height: 4rem;
            position: relative;
            @extend %m-r-full;
            &::before {
                content: "";
                display: block;
                width: 3.8rem;
                height: 3.8rem;
                @include position(null, 0, 0);
                @include border(1, c-white, 4);
                @extend %circle-radius;
            }
            &::after {
                content: "";
                display: block;
                width: 0.9rem;
                height: 0.9rem;
                z-index: var(--overlay-image);
                @include position(null, 0, 0);
                @include border(1, c-white, 4);
                @extend %circle-radius;
            }
            img {
                width: 4rem;
                height: 4rem;
                // @include border(1,c-white,4);
                object-fit: cover;
                @extend %d-block;
                @extend %m-zero;
                @extend %circle-radius;
                @extend %m-r-full;
                @extend %c-white-2-bg-10;
            }
            .logo {
                width: 1.1rem;
                height: 1.1rem;
                z-index: var(--overlay-image);
                @include position(null, 0, 0);
            }
        }
        &-head {
            @extend %d-none;
        }
        &-body {
            @extend %p-b-full;
        }
        .t-pos {
            width: 2rem;
            flex-shrink: 0;
            @extend %c-orange-10;
            @extend %font-14-pr;
            .text {
                @extend %font-14-pr;
            }
        }
        .shortname {
            @extend %d-none;
        }
        .fullname {
            @extend %font-12-pr;
            line-height: 1;
        }
        .name {
            @extend %d-block;
            @extend %font-12-pr;
            @extend %c-blue-10;
            &.fname {
                opacity: 0.6;
            }
        }
    }
    .swiper {
        &-button-prev,
        &-button-next {
            display: none;
            width: 4rem;
            height: 4.5rem;
            top: 0;
            @include linear-gradient(270deg,
                var(--c-blue) 62.95%,
                transparent 106.94%);
            &::after {
                @extend %c-white-10;
            }
        }
        &-button-disabled {
            display: none;
        }
        &-button-prev {
            left: 0;
            @include linear-gradient(90deg,
                var(--c-blue) 62.95%,
                transparent 106.94%);
        }
        &-button-next {
            right: 0;
            justify-content: flex-end;
            @include linear-gradient(270deg,
                var(--c-blue) 62.95%,
                transparent 106.94%);
        }
        &-scrollbar {
            @extend %d-none;
        }
    }
    .waf-footer {
        .more-tab {
            @extend %p-full;
            @extend %text-center;
            @extend %c-blue-bg-10;
            a {
                @extend %c-white-10;
                @extend %font-16-pb;
            }
        }
    }
    .no-data-wrap {
        @extend %m-y-half;
    }
}
.waf-standings.widget-layout-07 {
    .layout-wrapper {
        border-radius: var(--half-radius) var(--half-radius) 0 0;
    }
    .table,
    .table-body {
        padding-bottom: 0;
    }
    .head-tab {
        border-bottom: 0;
    }
}
@media (min-width: $tablet-min-width) {
    .waf-standings {
        margin-inline: 0;
        .tabs.secondary {
            overflow: unset;
            gap: unset;
        }
        .table {
            .t-pos {
                @include font(16);
                .text {
                    @include font(16);
                }
            }
        }
        .table-responsive {
            height: 58.5rem;
        }
        .waf-body {
            height: calc(100% - 10.4rem);
        }
        .layout-wrapper {
            height: 100%;
        }
        .tab-container {
            height: calc(100% - 9.6rem);
        }
        .swiper {
            &-button-prev,
            &-button-next {
                display: flex;
            }
        }
    }
    .rank-list-wrapper {
        .swiper-button-prev.swiper-button-disabled {
            opacity: 0;
        }
        .swiper-button-next.swiper-button-disabled {
            opacity: 0;
        }
    }
    .waf-standings.widget-layout-07 {
        // margin-bottom: 13.5rem;
        .layout-wrapper {
            // padding-bottom: 5.6rem;
        }
        .table-responsive {
            // height: auto;
        }
        .waf-head .head-tab {
            // bottom: 0;
        }
    }
}
@media (min-width:$xl-desktop-min-width) {
    .waf-standings {
        .table-responsive {
            height: 100%;
        }
    }
}