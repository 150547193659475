@use '../config/' as *;
.sticky-showcase {
    > div {
        @extend %p-zero;
    }
}
.waf-showcase {
    @extend %p-zero;
    @extend %m-zero;
    @extend %dash-pagination;
    .waf-head {
        @extend %d-none;
    }
    .article {
        &-list {
            @extend %h-100;
            // @extend %flex-row-n-n;
        }
        &-item {
            position: relative;
            @extend %h-100;
        }
        &-wrap {
            position: relative;
            @extend %h-100;
        }
        &-thumbnail {
            position: relative;
            @extend %h-100;
            &::after {
                content: '';
                background: linear-gradient(0deg, hsla(var(--hsl-c-black)/0.4), hsla(var(--hsl-c-black)/0.4)), radial-gradient(50% 50% at 50% 50%, hsla(var(--hsl-c-blue)/0) 25.52%, hsla(var(--hsl-c-blue)/0.4) 100%);
                @include position-combo(inset);
            }
            .img-box {
                @include border-radius(unset);
                img {
                    object-fit: cover;
                }
            }
        }
        &-content {
            @include position(null, null, calc(var(--two-space) + var(--half-space)), 0);
            @extend %position-b-l;
            @extend %p-full;
            @extend %flex-column;
            a {
                order: 1;
            }
        }
        &-title {
            @extend %uppercase;
            @extend %c-pure-white-10;
            @include truncate-vertical(2, 36, 44, $font-sb);
            height: auto;
        }
        &-description {
            order: 3;
            @extend %m-b-full;
            & > p {
                @include font(14, 24, $font-pr);
                @extend %c-pure-white-10;
            }
        }
        &-meta {
            @extend %d-none;
        }
        &-readmore {
            order: 4;
            flex-wrap: wrap;
            @include flex-config(flex, column-reverse);
            @extend %w-100;
            .btn-site {
                @extend %w-100;
                @extend %text-center;
                @extend %font-14-pr;
                @extend %m-y-half;
                // @extend .btn-outline;
                &.btn-fill {
                    &::after {
                        @include icon('\e81d');
                        @extend %m-l-half;
                    }
                }
                &.read-now {
                    margin-left: 0;
                    &::after {
                        content: unset;
                    }
                }
            }
        }
    }
    .status {
        width: max-content;
        border-radius: var(--full-radius);
        backdrop-filter: blur(0.4rem);
        @include border(0.5, c-white, 5);
        @extend %c-white-bg-2;
        @extend %m-b-half;
        @extend %p-r-half;
        @extend %c-white-10;
        @extend %font-14-pr;
    }
    .tagline {
        // border-radius: var(--full-radius);
        // backdrop-filter: blur(0.4rem);
        // @include border(0.5, c-white, 5);
        // @extend %c-white-bg-2;
        order: 2;
        width: max-content;
        @extend %m-b-half;
        @extend %p-r-half;
        @extend %c-white-10;
        @extend %font-16-pr;
        a {
            @extend %c-orange-10;
            @extend %font-16-pr;
        }
    }
    .thumbnail {
        z-index: var(--zindex-pattern);
        @include position-combo(inset);
        @extend %h-100;
    }
    .content {
        @extend %d-none;
    }
    // .input-group{
    @include input(text);
    @include input('radio');
    @include input('checkbox');
    .swiper {
        &-button-prev,
        &-button-next {
            @extend %d-none;
        }
    }
    .waf-poll.hide-poll {
        @extend %d-none;
    }
}
@media (min-width:$tablet-min-width) {
    .waf-showcase {
        .layout-wrapper {
            max-width: 100%;
        }
        .preview-swiper {
            margin-top: calc(-1*var(--header-height));
            height: unset;
        }
        .article {
            &-content {
                width: 100%;
                padding-left: var(--container-white-space);
                @include position(null, null, 50%);
                @include translate(0, 50%);
            }
            &-readmore {
                flex-wrap: unset;
                // width: 70%;
                @include flex-config(flex, row, space-between);
                .btn-site {
                    width: 50%;
                    margin-inline: var(--quarter-space);
                    @include font(18);
                }
                .btn-fill {
                    order: 1;
                    margin-left: var(--half-space);
                }
            }
            &-title {
                @include truncate-vertical(2, 40, 50, $font-sb);
                height: auto;
            }
            &-description {
                & > p {
                    @include font(18, 30);
                }
            }
        }
        &.dependency-scorestrip {
            .article-content {
                width: 75%;
            }
        }
        .swiper {
            &-button-prev,
            &-button-next {
                width: unset;
                aspect-ratio: unset;
                display: flex;
                &::after {
                    content: "\E803";
                    color: var(--c-white);
                    font-size: 1.6rem;
                }
            }
            &-button-prev {
                left: 2.8rem;
                transform: rotate(180deg);
            }
            &-button-next {
                right: 2.8rem;
            }
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-showcase {
        .preview-swiper {
            height: calc(var(--window-inner-height) - var(--eventstrip));
        }
        .article {
            &-content {
                width: 50%;
            }
            &-title {
                @include truncate-vertical(4, 64, 70, $font-sb);
                height: auto;
            }
        }
    }
    .homepage .waf-showcase .article-thumbnail::after {
        background: radial-gradient(50% 50% at 50% 50%, hsl(var(--hsl-c-blue) / 0) 25.52%, hsl(var(--hsl-c-blue) / .4) 100%),
linear-gradient(0deg, hsl(var(--hsl-c-black) / .4), hsl(var(--hsl-c-black) / .4));
    }
}
@media (min-width:$large-desktop-min-width) {
    .waf-showcase {
        .article-readmore {
            width: 70%;
        }
    }
}