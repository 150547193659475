@use "../config/" as *;
.waf-poll {
    padding: var(--two-space) var(--full-space);
    @extend %c-blue-bg-10;
    @extend %m-y-zero;
    .tagline {
        color: var(--c-orange);
        font-size: 1.4rem;
        @extend %m-y-zero;
    }
    .title {
        line-height: 1.5;
        @extend %m-b-one-n-half;
        @extend %c-white-10;
        @extend %font-16-pb;
    }
    .poll {
        &-item {
            border-radius: 1rem;
            border: .1rem solid var(--c-white);
            background: hsl(var(--hsl-c-white)/ .2);
            box-shadow: 0 .4rem 1.5rem 0 hsl(var(--hsl-c-black)/ .2);
            backdrop-filter: unset;
            @extend %card;
            @extend %w-100;
            @extend %relative;
        }
        &-info {
            @extend %flex;
        }
        &-content {
            @extend %flex-column-c-n;
            @extend %m-l-full;
            .fname {
                @extend %d-block;
                @extend %font-12-pr;
                @extend %c-white-8;
            }
            .lname {
                @extend %c-white-10;
                @extend %font-14-pr;
            }
        }
    }
    .btn-close {
        @include position(var(--half-space), var(--half-space));
        @extend %font-zero;
        &::before {
            content: "\e804";
            font: 2.4rem/1 $font-icon;
            @extend %c-white-10;
        }
    }
    .radio-box-wrap {
        @extend %flex-sb-c;
        @extend %p-y-half;
        @extend %gap-full;
        &:not(:last-child) {
            @include border(0.5, c-white, 2, bottom);
        }
        .number {
            @extend %c-white-10;
            @extend %font-18-psb;
        }
    }
    .winner .number {
        color: var(--c-orange);
    }
    // Styles for Quiz
    .quiz-section {
        --_radio-btn-size: 2rem;
        .poll {
            &-thumbnail .image {
                @extend %c-white-bg-2;
            }
            &-info {
                width: calc(100% - var(--_radio-btn-size) - var(--half-space));
            }
            &-content {
                width: calc(100% - 4rem - var(--full-space));
            }
        }
        .radio-box-wrap {
            @extend %gap-half;
            @extend %p-x-half;
            &:not(:last-child) {
                border-bottom: 0;
                @extend %m-b-full;
                @extend %relative;
                &::after {
                    content: "";
                    height: .1rem;
                    @include position(null, null, -.9rem, 0);
                    @extend %w-100;
                    @extend %c-white-bg-2;
                }
            }
            &.correct, &.incorrect {
                --_radio-btn-size: 2.8rem;
                border: .2rem solid hsl(var(--hsl-c-pure-white)/ .5);
                border-radius: 1rem;
                .selected {
                    @extend %flex;
                }
                .number {
                    @extend %font-zero;
                    &::after {
                        font: 2.8rem/1 $font-icon;
                    }
                }
            }
            &.correct {
                @extend %c-blue-bg-10;
                .number::after {
                    content: "\E805";
                }
            }
            &.incorrect {
                @extend %c-error-bg-10;
                .number::after {
                    content: "\E804";
                }
            }
        }
        .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-poll {
        --_card-padding-top: 1.6rem;
        padding-inline: 0;
        max-width: 40rem;
        min-width: 31rem;
        z-index: var(--zindex1);
        background: transparent;
        @include position-combo(y-center, var(--container-white-space));
        .poll-item {
            padding-top: 4rem;
            backdrop-filter: blur(1.25rem);
        }
        .btn-close {
            top: var(--full-space);
            right: var(--full-space);
        }
    }
}
@media (min-width:$large-desktop-min-width) {
    .waf-poll {
        max-width: 46.4rem;
    }
}